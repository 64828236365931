import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Practice } from '../../types/practice';
import { useBoolean } from '../../hooks/use-boolean';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  practice: Practice;
};

export default function PracticeRosterTableRow({ practice, selected }: Props) {

  const quickView = useBoolean();
  const theme = useTheme();


  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{practice.legalEntity || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{practice.displayName || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{practice.phoneNumber || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{practice.email || 'N/A'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <SearchIcon onClick={() => {quickView.onTrue()}} sx={{ color: '#637381', cursor: 'pointer' }} />
        </TableCell>
      </TableRow>

      <Dialog
        open={quickView.value}
        onClose={quickView.onFalse}
        PaperProps={{
          style: {
            width: '800px',
            maxWidth: '800px',
            height: '',
          },
        }}
      >
        <DialogContent sx={{ height: 'auto' }}>
          {/* Not my favorite fix ever, but this box and its padding do get rid of the scrollbar when it isn't needed */}
          <Box sx={{ height: 'auto', padding: '15px' }}>
            {/* the styling for readonly, disabled fields has to be manually applied here since we can't use RHFTextField on its own */}
            <Grid container spacing={3}>
              <Grid xs={12}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: '400px', pt: 3 }}
                >
                  <Box style={{ height: '350px', width:'350px', overflow: 'hidden', borderRadius: '15px' }}>
                    {/* This img src might need a .jpg on the end, not sure at the moment */}
                    <img
                      src={`https://storage.googleapis.com/unify_public/practice_logos/${practice.id}`}
                      alt="profilePicture"
                      style={{ width: '100%', height:'100%', objectFit:'cover'}}
                      onError={(e) => {e.currentTarget.src="https://storage.googleapis.com/unify-mental-health/No_Photo_Available.jpg"}}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Legal Name"
                  value={practice.legalEntity || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Display Name"
                  value={practice.displayName || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="State of Incorporation"
                  value={practice.state || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Date of Incorporation"
                  value={practice.dateOfIncorporation || 'N/A'}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Bio"
                  minRows={3}
                  value={practice.bio || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Phone Number"
                  value={practice.phoneNumber || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Email"
                  value={practice.email || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Owner"
                  value={practice.owner || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Owner Email"
                  value={practice.ownerEmail || 'N/A'}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '10px',
                  }}
                  disabled
                  label="Email Domain"
                  value={practice.emailDomain || 'N/A'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={quickView.onFalse}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
