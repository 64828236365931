import axios from 'axios';
import * as React from 'react';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import { NAV, HEADER } from '../config-layout';
import { useRouter } from '../../routes/hooks';
import { useBoolean } from '../../hooks/use-boolean';
import { AuthContext } from '../../auth/context/auth0';
import AccountPopover from '../common/account-popover';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { user } = useAuth0();
  const { providerList, currentUser } = useContext(AuthContext);
  const settings = useSettingsContext();
  const spoofingQuickView = useBoolean();
  const router = useRouter();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  // Function to handle form submission with axios
  const spoofProvider = async (providerEmail: String) => {
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/auth/spoof-provider`, { providerEmail });

      if (response.status === 200) {
        // Handle success
        console.log('Spoof request sent successfully');
      } else {
        // Handle error
        console.error('Failed to send spoof request');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {(user && (user.email === "daniel.meas@unifymentalhealth.com" || user.email === "john.stevens@unifymentalhealth.com" || user.email === "kevin.lehtinen@unifymentalhealth.com" || user.email === "seva.burmaka@unifymentalhealth.com")) && (
          <>
            <Collapse
              orientation='horizontal'
              in={spoofingQuickView.value}
              easing='linear'
            >
              <Typography sx={{ whiteSpace:'nowrap'}}>Acting as: </Typography>
            </Collapse>
            <Typography>{currentUser?.email ?? user?.email}</Typography>
            <Collapse
            orientation='horizontal'
            in={spoofingQuickView.value}
            easing='linear'
            >
              <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
              >
                <FormControl sx={{ width:'300px' }}>
                  <Autocomplete
                    options={providerList || []}
                    // getOptionLabel={(option) => option || ''}  // Use 'email' as the display field
                    onChange={(event, newValue) => {
                      if(newValue) {
                        spoofProvider(newValue).then(router.reload);
                      }

                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Spoof new provider"
                        variant="outlined"
                      />
                    )}
                    sx={{
                      maxHeight: 400,
                      overflow: 'auto',
                      paddingY:1
                    }}
                  />
                </FormControl>
                <Button variant='contained' onClick={() => spoofProvider('robot@meridian-counseling.com').then(router.reload)}>
                  Reset
                </Button>
              </Stack>
            </Collapse>
            <Button onClick={spoofingQuickView.onToggle} variant='soft'>
              Spoofing
            </Button>
          </>
        )}
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
