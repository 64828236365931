import { forwardRef } from 'react';

import Box, { BoxProps } from '@mui/material/Box';


// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    // const theme = useTheme();
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 240,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
        // onClick={() => router.replace("/dashboard")}
      >
        <img alt='unify mental health logo' src='https://storage.googleapis.com/unify_public/unify.png'/>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Box sx={{ display: 'contents', cursor: 'pointer' }}>
        {logo}
      </Box>
    );
  }
);

export default Logo;
