import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';

import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows, TableHeadCustom, TablePaginationCustom,
} from 'src/components/table';

import { useRouter } from '../../routes/hooks';
import { Practice } from '../../types/practice';
import { LoadingScreen } from '../../components/loading-screen';
import PracticeRosterTableRow from './practice-roster-table-row';
import TableSearchHeader from '../../components/table/table-search-header';
import { IUserTableFilters, IUserTableFilterValue } from '../../types/user';
import { ResponseErrorChecker } from '../../pages/dashboard/universalComponents/responseErrorChecker';


const TABLE_HEAD = [
  { id: 'name', label: 'Legal Name', width: 180 },
  { id: 'supervisor', label: 'Display Name', width: 180 },
  { id: 'practice', label: 'Phone', width: 220 },
  { id: 'license', label: 'Email', width: 220 },
  { id: '', label: 'View', width: 50}
];

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  status: 'all',
};

export default function PracticeRosterTable() {
  const { logout } = useAuth0();
  const { replace } = useRouter();
  const table = useTable({defaultDense: true, defaultOrderBy: 'createdAt', defaultOrder: 'asc', defaultRowsPerPage:25});
  const settings = useSettingsContext();
  const [practices, setPractices] = useState<Practice[]>([]);
  const [tableLength, setTableLength] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const getTableData = async () => {
      const response = await ResponseErrorChecker(() => axios.get(`${import.meta.env.VITE_API_URL}/practices/admin`), logout, replace);
      if(response !== null) {
        setPractices(response.data);
        setTableLength(response.data.length);
        setHasLoaded(true);
      }
    };

    getTableData();
  }, [logout, replace]);

  const dataInPage = practices.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const notFound = !dataInPage.length;

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilters = useCallback((name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const filteredProviders = applyFilter({
    practices,
    filters,
  });



  return (
    hasLoaded ?
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Box>
          <Stack direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Practice Roster
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Card>
          <Stack direction='row' alignItems="center">
            <Box sx={{width:'100%'}}>
              <TableSearchHeader filters={filters} onFilters={handleFilters}/>
            </Box>
          </Stack>


          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={practices.length}
                />
                <TableBody>
                  {filteredProviders
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <PracticeRosterTableRow
                        key={row.id}
                        practice={row}
                        selected={false}
                      />
                    ))}

                  <TableEmptyRows
                    height={44}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, filteredProviders.length)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tableLength}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />

        </Card>
      </Container>
      :
      <LoadingScreen/>
  );
}

function applyFilter({
                       practices,
                       // filters,
                     }: {
  practices: Practice[];
  filters: IUserTableFilters;
}) {
  // const { name } = filters;

  const stabilizedThis = practices.map((el, index) => [el, index] as const);

  practices = stabilizedThis.map((el) => el[0]);
  // if (name) {
  //   practices = practices.filter(
  //     (provider) =>
  //       provider.firstName && provider.firstName.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //       || provider.lastName && provider.lastName.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //       || provider.supervisorName && provider.supervisorName.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //   );
  // }


  return practices;
}
