import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
// AUTH0
const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'));
const Auth0Callback = lazy(() => import('src/pages/auth/auth0/callback'));

// External Login
const ExternalLogin = lazy(() => import('src/pages/login/external-login'));

// ----------------------------------------------------------------------

const authAuth0 = {
  path: 'auth0',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <Auth0LoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'callback',
      element: (
        <GuestGuard>
          <Auth0Callback />
        </GuestGuard>
      ),
    },
  ],
};


export const authRoutes = [
  {
    path: 'auth',
    children: [authAuth0,
      {path:'external-login', element:<ExternalLogin />}],
  },
];
