import { Helmet } from 'react-helmet-async';

import PracticeRosterTable from '../../../sections/admin-practice-roster/practice-roster-table';

export default function PracticeRosterPage() {
  return (
    <>
      <Helmet>
        <title>Practice Roster</title>
      </Helmet>
      <PracticeRosterTable />
    </>
  );
}
