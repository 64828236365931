import { enqueueSnackbar } from 'notistack';
import { isAxiosError, AxiosResponse } from 'axios';


// Example of how to use this

// const { logout } = useAuth0();
// const { replace } = useRouter();
// const response = await ResponseErrorChecker(() => axios.get(`${import.meta.env.VITE_API_URL}/providers`), logout, replace);

// then use your response as you normally would

const httpBootingEnabled = import.meta.env.VITE_HTTP_RESPONSE_BOOTING === 'ON';

export async function ResponseErrorChecker (request: () => Promise<AxiosResponse>, logout: Function, replace: Function) : Promise<AxiosResponse | null> {

  try {
    // @ts-ignore
    return await checkResponse(request, logout, replace, 1);
  } catch (error) {
    console.error('Request failed:', error);
    enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
    return null;
  }

}

const checkResponse = async (request: () => Promise<AxiosResponse>, logout: Function, replace: Function, attempt: number) => {
  try{
    return await request();
  } catch (error) {
    if(isAxiosError(error) && error.response) {
      if(httpBootingEnabled) {
        switch (error.response.status){
          case 400:
            enqueueSnackbar('Bad request. Please check your input.', { variant: 'warning' });
            return null;

          case 401:
            enqueueSnackbar('You have been logged out. Please log in again.', { variant: 'error' });
            setTimeout(() => logout(), 1500);
            return null;

          case 403:
            enqueueSnackbar('You do not have permission to access this resource.', { variant: 'warning' });
            replace('/403')
            return null;

          case 404:
            enqueueSnackbar('The requested resource was not found.', { variant: 'error' });
            replace('/404')
            return null;

          case 418:
            enqueueSnackbar("I'm a teapot!", { variant: 'info' });
            return null;

          case 429:
            enqueueSnackbar('Too many requests. Please try again later.', { variant: 'warning' });
            return null;

          case 500:
          case 502:
          case 503:
          case 504:
            setTimeout(() => FiveHundredError(request, logout, replace, attempt), 1000*attempt);
            return null;

          default:
            enqueueSnackbar('An unexpected http response occurred', { variant: 'error' });
            console.log('An unexpected http response occurred', error)
            return null;
        }
      } else {
        enqueueSnackbar(`HTTP: ${error.response?.status}`, { variant: 'warning' });
      }

    }
    return null;
  }
}


const FiveHundredError = async (request: () => Promise<AxiosResponse>, logout: Function, replace: Function, attempt: number) => {
  if( attempt > 3 ) {
    enqueueSnackbar('Final Attempt Failed. No more attempts will be made', { variant: 'error' });
    replace('/500');
    return null;
  } else {
    try {
      enqueueSnackbar('Error retrieving data. Trying again', { variant: 'error' });
      return await checkResponse(request, logout, replace, attempt+1);
    } catch (error) {
      console.error('Request failed:', error);
      enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      return null;
    }
  }

}
